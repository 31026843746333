<template>
    <r-e-dialog :footerBtnName="footerBtnName" title="续租" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh"
                width="700px" @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="125px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="房源信息">
                    <el-input v-model="currentHouse.address" disabled placeholder="请填写房型名称（15个字以内）"
                              style="width: 520px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="租赁时间" prop="monthSize">
                    <div class="flex justify-between" style="width: 200px;">
                        <el-input-number v-model="formPublish.monthSize" style="width: 160px" :min="1" label="租聘时间"/>
                        <span>(月)</span>
                    </div>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="续租开始日期" prop="startDate">
                    <el-date-picker disabled v-model="formPublish.startDate" type="date" placeholder="选择日期"
                                    style="width: 200px;"/>
                </el-form-item>
                <el-form-item label="续租结束日期" prop="endDate">
                    <el-date-picker disabled v-model="formPublish.endDate" type="date" placeholder="选择日期"
                                    style="width: 200px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="紧急联系人" prop="contact">
                    <el-input type="text" v-model="formPublish.contact" placeholder="请填写紧急联系人"/>
                </el-form-item>
                <el-form-item label="紧急联系人电话" prop="contactTel">
                    <el-input type="text" v-model="formPublish.contactTel" :maxlength="11" placeholder="请填写紧急联系人电话"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="身份证地址">
                    <el-input type="text" v-model="formPublish.addressId" placeholder="请填写身份证地址"/>
                </el-form-item>
                <el-form-item label="邮箱">
                    <el-input type="text" v-model="formPublish.eMail" placeholder="请填写邮箱"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="月租金" prop="amount">
                    <el-input type="number" v-model="formPublish.amount" placeholder="请填写月租金" disabled
                              v-loading="loading"/>
                </el-form-item>
                <el-form-item label="服务费" prop="tip">
                    <el-input type="number" v-model="formPublish.tip" placeholder="请填写服务费" disabled
                              v-loading="loading"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="是否生成账单">
                    <div style="width: 200px;">
                        <el-radio-group v-model="formPublish.bill">
                            <el-radio-button :label="true">是</el-radio-button>
                            <el-radio-button :label="false">否</el-radio-button>
                        </el-radio-group>
                    </div>
                </el-form-item>
                <el-form-item label="是否生成电子合同">
                    <el-radio-group v-model="formPublish.face">
                        <el-radio-button :label="true">是</el-radio-button>
                        <el-radio-button :label="false">否</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
          <div class="flex">
            <el-form-item label="经办人" prop="manager">
              <el-input  v-model="formPublish.manager" placeholder="请填写经办人"/>
            </el-form-item>
            <el-form-item label="管理人" prop="keeper">
              <el-input v-model="formPublish.keeper" placeholder="请填写管理人"/>
            </el-form-item>
          </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {renewalContract, getSignAmount, isRelet,getContractElectType} from "@/api/contract"
import {isbzf} from "@/api/house-resource";
import {MessageSuccess, MessageError} from "@custom/message";
import {timeFormat, ParseFloatFormat, ParseIntFormat} from "@custom";

export default {
    name: "dialog-contract-xuqian",
    data() {
        return {
          footerBtnName:['取消','审批'],
            dialogVisible: false,
            formPublish: {
                monthSize: 1,
                startDate: "",
                endDate: "",
                contact: "",
                contactTel: "",
                amount: 0,
                tip: 0,
                bill: true,
                face: true,
                manager:'',// 经办人
                keeper:'',// 管理人
            },
            rules: {
                dt: [{required: true, message: '请选择日期', trigger: 'change'}],
                monthSize: [{required: true, message: '请输入租赁时间', trigger: 'blur'}],
                amount: [{required: true, message: '请输入月租金', trigger: 'blur'}],
                tip: [{required: true, message: '请输入服务费', trigger: 'blur'}],
                endDate: [{required: true, message: '请选择结束日期', trigger: 'change'}],
                contact: [{required: true, message: '请输入紧急联系人', trigger: 'blur'}],
                contactTel: [{required: true, message: '请输入紧急联系人电话', trigger: 'blur'}],
                manager: [{required: true, message: '请输入经办人', trigger: 'blur'}],
                keeper: [{required: true, message: '请输入管理人', trigger: 'blur'}],
            },

            loading: false,

            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            isBzfOk: null,
        };
    },
    props: {
        currentHouse: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        //打开续租弹框方法
        openDialog(data) {
            let that = this;
            //获取当前房间
            const {apartmentUuid} = this.currentHouse;
            //获取续租合同uuid，结束时间，服务费，房租，紧急联系人联系人电话
            let {uuid, endDate, serviceAmount, rent, contactName, contactPhone} = data;
            //服务费，房租金额转换
            serviceAmount = ParseFloatFormat(serviceAmount);
            rent = ParseFloatFormat(rent);
            //续租开始时间计算加转换（开始时间=上一份合同结束时间+1天）
            let startDate = timeFormat(new Date(endDate + (8.64e7)));
            //保存旧续租数据
            let oldData = this.formPublish;
            //初始化数据
            this.formPublish = {
                ...oldData, bill: true, face: true, amount: rent, tip: serviceAmount, contact: contactName,
                contactTel: contactPhone, monthSize: 1, contractUuid: uuid, startDate,
            }
            //计算续租结束时间
            this.setEndDate(1);
            //获取房间金额
            this.getSignAmount();
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            //调用接口查当前房间是否是保租房
            isbzf(apartmentUuid).then(({info: isok}) => {
                that.isBzfOk = !isok;
                //打开弹窗
                this.dialogVisible = true;
            }).finally(() => loading.close());
        },

        // 点击确认按钮事件
        clickSubmit() {
            let that = this;
            const {loadingOptions, isBzfOk} = that;
            //表单校验
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    //解构数据
                    let {
                        contractUuid, monthSize, amount, tip, startDate, endDate, addressId, contact, contactTel,
                        eMail, bill, face,manager, keeper
                    } = that.formPublish;
                    //金额转换
                    amount = ParseIntFormat(amount);
                    tip = ParseIntFormat(tip);
                    //判断续租时间是否在范围内 6-36个月
                    if (monthSize < 6 || monthSize > 36) {
                        MessageError('必须是6个月起租，最长不能超过3年');
                        return;
                    }
                    //封装数据
                    const data = {
                        contractUuid, monthSize, amount, tip, startDate, endDate, addressId, contact, contactTel, eMail,
                        bill, face, isNewElectronics: isBzfOk,manager, keeper,isCreateElectContract : true
                    }

                    let loading = that.$loading(loadingOptions);
                    //调用接口查询续租的合同是否有未支付账单
                    isRelet(contractUuid).then(res => {
                        let {info} = res;
                        //判断是否有未支付账单
                        if (info) {
                            //无未支付账单
                            loading = this.$loading(loadingOptions);
                            //调用接口，传递数据，续租合同
                            renewalContract(data).then(res => {
                                let {resultCode,info: { message, contract}} = res;
                                //code=200续租成功
                                // if (resultCode === 1000) {
                                    MessageSuccess('合同续租审批成功');
                                    //判断contract有无数据
                                    // 续签修改流程签约变成审批注释下面代码
                                    // if (contract) {
                                    //     //有数据个人续租成功
                                    //     let {data} = contract;
                                    //     //判断data是否有数据
                                    //     if (data) {
                                    //         //有数据，拿出e签宝签署连接url
                                    //         let {url} = data;
                                    //         //新界面打开
                                    //         let tempwindow = window.open("", '_blank');
                                    //         //新界面更换地址
                                    //         tempwindow.location = url;
                                    //     } else {
                                    //         //赋值合同uuid
                                    //         contract.contractUuid = contract.uuid;
                                    //         //保存数据到localStorage
                                    //         localStorage.setItem("paramData", JSON.stringify(contract));
                                    //         const {apartmentUuid} = this.currentHouse;
                                    //         // 获取电子合同类型  0林里桥整租 1伶俐人才孵化基地整租
                                    //         getContractElectType({apartmentUuid}).then((res) => {
                                    //             let {info} = res;
                                    //             if(info.electContractType === 0){
                                    //                 const newWindow = this.$router.resolve({path: "/test-canvas"});
                                    //                 //新界面打开
                                    //                 window.open(newWindow.href, "_blank");
                                    //             }else if (info.electContractType === 1){
                                    //                 const newWindows = this.$router.resolve({path: "/test-canvas-talent"});
                                    //                 //新界面打开
                                    //                 window.open(newWindows.href, "_blank");
                                    //             }
                                    //             // MessageSuccess("生成合同成功");
                                    //             //调用方法，关闭添加合同弹框
                                    //             // this.clickCancel();
                                    //         }).finally(() => loading.close());
                                    //         // //新建路由
                                    //         // const newWindows = that.$router.resolve({path: "/test-canvas"});
                                    //         // //新界面打开
                                    //         // window.open(newWindows.href, '_blank');
                                    //     }
                                    // }
                                    //调用取消按钮事件，关闭弹窗
                                    that.clickCancel();
                                // } else MessageError(message);
                            }).finally(() => loading.close());
                        } else {
                            //有未支付账单提示支付
                            const h = that.$createElement;
                            that.$msgbox({
                                title: '注意',
                                message: h('div',
                                    {style: {color: 'red', fontSize: 16, fontWeight: 600}},
                                    '存在未缴纳账单，需要缴纳完毕后方可续租'),
                                type: 'warning'
                            }).then(() => {
                            }).catch(() => {});
                        }
                    }).finally(() => loading.close());
                }
            });
        },

        // 点击取消按钮事件
        clickCancel() {
            //调用上层方法刷新合同列表
            this.$emit('handleSearch');
            //关闭弹窗
            this.dialogVisible = false;
            //重置表单
            this.$refs["formPublish"].resetFields();
        },

        //计算续租结束时间方法
        setEndDate(value) {
            let that = this;
            if (value && value !== 0) {
                let {startDate} = this.formPublish;
                let date = new Date(startDate);
                let startYear = date.getFullYear();     //获取开始年
                let startMonth = date.getMonth() + 1;   // 获取开始月
                let startDay = date.getDate();          //获取开始日

                if (startDay === 1) value -= 1;          //开始日为1号，增加月数减1

                let year = parseInt(value / 12);     //月数换算年数
                value = value % 12;
                startMonth = startMonth + value;
                let endMonth = null;    //结束月
                if (startMonth > 12) {     //开始月加 余月数 大于12 表示还有满年，年数加1 结束月减12
                    year += 1;
                    endMonth = startMonth - 12;
                } else endMonth = startMonth;   //其他情况 结束月加月数

                let endYear = startYear + year; //结束年
                let endDay = null;  //结束日
                let d = new Date(endYear, endMonth, 0);         // 获取结束月当月最后一天
                if (startDay === 1) endDay = d.getDate();            // 开始日 为1号 结束日为结束月最后一天
                else if (endMonth === 2 && startDay > 29) endDay = d.getDate(); //结束月为2月 开始日 大于29号 结束日为当月(2月)最后一天
                else if (startDay - 1 === d.getDate()) endDay = d.getDate() - 1; //开始日减1 等于结束月最后一天 结束日为当月最后一天减1
                else if (startDay >= 2) endDay = startDay - 1;   //开始日大于等于2 结束日为开始日减1
                // 月份从0开始，所以结束月减1 endMonth - 1
                this.formPublish.endDate = timeFormat(new Date(endYear, endMonth - 1, endDay));
                //获取房间金额
                that.getSignAmount();
            }
        },

        //获取房间金额方法
        getSignAmount() {
            let that = this;
            //解构数据
            let {apartmentUuid} = this.currentHouse;
            let {startDate, endDate} = that.formPublish;
            that.loading = true;
            //调用接口，根据租赁时间查询该房间每月租金
            getSignAmount({apartmentUuid, startDate, endDate}).then(res => {
                //获取数据
                let {info = {}} = res;
                info = info || {};
                //解构金额
                let {displayAmount = 0, bone = 0, tipAmount = 0} = info;
                displayAmount = displayAmount || 0;
                bone = bone || 0;
                tipAmount = tipAmount || 0;
                //金额转换，并赋值展示
                that.formPublish.amount = ParseFloatFormat(displayAmount);
                that.formPublish.tip = ParseFloatFormat(tipAmount);
                that.loading = false;
            });
        }
    },
    watch: {
        'formPublish.monthSize'(value) {
            if (value && value !== 0 && this.dialogVisible) this.setEndDate(value);
        }
    }
}
</script>

<style scoped>

</style>
